<template>
  <b-card no-body>
    <HTMLTitle :title="$t('navigation.menu.dashboard')"></HTMLTitle>

    <card-header :title="$t('navigation.menu.dashboard')" :icon="['fas', 'home']"></card-header>

    <b-card-body>
      <span><strong>ATENÇÃO</strong>: esta é uma versão preliminar da nova versão do sistema (<strong>MDLIVRE APP</strong>) e tem o objetivo de mostrar algumas novas
        funcionalidades e melhorias, assim como uma visão geral do comportamento do sistema.<br />
        Várias funcionalidades estão desabilitadas ou não foram implementadas ainda e serão liberadas de forma gradativa. Para
        as funcionalidades ainda não disponibilizadas aqui, você deve continuar utilizando-as como faz hoje através do
        <strong>MDLIVRE ADMIN</strong> (https://admin.mdlivre.com.br).<br /><br /></span>
      <h3>Funcionalidades Implementadas na versão APP</h3>

      <h5>Funcionalidades Gerais</h5>
      <ul>
        <li>Troca de contas associadas</li>
        <li>Alteração de idioma</li>
        <li>Troca de tema claro/escuro</li>
        <li>Alteração de Senha e Email - Menu de Perfil > Configurações</li>
        <li>Ajuda e suporte - Menu de Perfil > Ajuda</li>
        <li>Sobre - Menu de Perfil > Sobre</li>
        <li>Adição à tela Inicial do celular para funcionamento semelhante à aplicativo (Android/Chrome ou iPhone/Safari)</li>
      </ul>

      <h5>Funcionalidades Específicas do MercadoLivre - Acesso pelo menu <strong>MercadoLivre</strong> e seus submenus</h5>
      <ul>
        <li>Acesso multi-contas. Gere o código de acesso de cada conta no MDLivre Admin para depois associar sua conta aqui.</li>
        <li>Relatório de Vendas - Menu MercadoLivre > Análises > Relatório de Vendas</li>
        <li>Recebimentos do MercadoPago - Menu MercadoLivre > MercadoPago > Recebimentos</li>
        <li>Visualização do Perfil - Menu MercadoLivre > Configurações > Perfil da Conta</li>
        <li>Importação de dados - Menu MercadoLivre > Configurações > Importação de Dados</li>
        <li>Gerenciamento de Listas de Bloqueio - Menu MercadoLivre > Configurações > Perfil da Conta</li>
      </ul>
      <h3>Próximas Funcionalidades</h3>
      <ul>
        <li>Gerenciamento de Permissões de Acesso.</li>
        <li>Lista de Anúncios</li>
      </ul>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody } from 'bootstrap-vue'
import HTMLTitle from '@core-md/layouts/components/HTMLTitle.vue'
import CardHeader from '@core-md/components/cards/CardHeader.vue'

export default {
  components: {
    BCard,
    BCardBody,
    HTMLTitle,
    CardHeader,
  },
}
</script>
